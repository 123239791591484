import { booleanAttribute, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'cpm-divider',
  standalone: true,
  imports: [],
  templateUrl: './divider.component.html',
  styleUrl: './divider.component.scss'
})
export class DividerComponent {
  @Input() label: string = '';
  @Input({ alias: 'line-style' }) lineStyle: string = '';
  @HostBinding('attr.no-margin')
  @Input({transform: booleanAttribute, alias: 'no-margin'}) noMargin: boolean = false;
  @HostBinding('attr.orientation')
  @Input() orientation?: string = 'vertical';
}
